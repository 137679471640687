import React from 'react';

const Eyes = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="120.893" height="24.158">
      <defs />
      <g id="Group_242" data-name="Group 242" transform="translate(-15492.488 -1351.274)">
        <path
          id="Path_275"
          d="M46.1-63.966a23.005 23.005 0 0 0 17.377 8 23 23 0 0 0 17.378-8zm17.375 12.627a27.67 27.67 0 0 1-23.846-13.778l-2.013-3.473h51.722l-2.013 3.472a27.668 27.668 0 0 1-23.85 13.779"
          data-name="Path 275"
          transform="translate(15524.043 1426.771)"
        />
        <path
          id="Path_276"
          d="M20.229-63.966a23.006 23.006 0 0 0 17.378 8 23 23 0 0 0 17.378-8zm17.378 12.627A27.67 27.67 0 0 1 13.76-65.117l-2.013-3.473h51.722l-2.013 3.472a27.668 27.668 0 0 1-23.849 13.779"
          data-name="Path 276"
          transform="translate(15480.741 1426.771)"
        />
        <path
          id="Path_279"
          d="M37.649-66.55h51.477v-4.623H37.649z"
          className="cls-1"
          data-name="Path 279"
          transform="translate(15524.099 1422.447)"
        />
        <path
          id="Path_280"
          d="M11.781-66.55h51.476v-4.623H11.781z"
          className="cls-1"
          data-name="Path 280"
          transform="translate(15480.798 1422.447)"
        />
      </g>
    </svg>
  );
};

export default Eyes;
