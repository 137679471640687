import React from 'react';

const Body = ({ className }) => {
  return (
    <svg
      className={className}
      css={`
        .cls-2-jungle-cat-body {
          stroke: #000;
          stroke-width: 5px;
          fill: none;
          stroke-miterlimit: 10;
        }
      `}
      xmlns="http://www.w3.org/2000/svg"
      width="230.458"
      height="280.227"
    >
      <defs />
      <g id="Group_241" data-name="Group 241" transform="translate(-15426.725 -1290.98)">
        <path
          id="Path_270"
          d="M15465.751 1509.189s3.252-171.948 25.115-211.545l31.712 26.934s32.484-4.208 58.539 0l31.913-31.1s15.6 56.911 19.432 91.424c1.434 12.919 4.262 131.071 4.262 131.071z"
          data-name="Path 270"
          strokeLinejoin="round"
          stroke="#000"
          strokeWidth="5"
          fill="#fff"
        />
        <path
          id="Path_271"
          d="M10.155-59.25l19.259-5.328-16.847-26.029"
          className="cls-2-jungle-cat-body"
          data-name="Path 271"
          transform="translate(15478.076 1389.916)"
        />
        <path
          id="Path_272"
          d="M70.043-59.591L50.785-64.92l16.847-26.028"
          className="cls-2-jungle-cat-body"
          data-name="Path 272"
          transform="translate(15546.086 1389.345)"
        />
        <path
          id="Path_281"
          d="M15430.259 1452.993s63.974-23.5 107.392-17.584 91.019 32.026 91.019 32.026 27.746 11.186 28.056 11.22.854 92.736 0 92.552-62.015-32.926-75.308-34.773-40.889-14.839-81.6-8.448a326.87 326.87 0 0 0-73.089 20.7z"
          data-name="Path 281"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Body;
