import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Flex } from '@rebass/grid';

import { Jungle } from '../components/404Page';

import withLayout from '../layout';

const NotFoundPage = () => (
  <Flex flexDirection="column" alignItems="center" mt={100} width="100vw">
    <h2
      css={`
        font-size: 30px;
        margin: 0 0 30px;
        line-height: 1;
      `}
    >
      <FormattedMessage id="error404.are you lost" />
    </h2>
    <h1
      css={`
        font-size: 150px;
        margin: 0;
        color: ${({ theme }) => theme.colors.green};
        line-height: 1;
      `}
    >
      404
    </h1>
    <Jungle />
  </Flex>
);

const customProps = {
  localeKey: 'error404',
  hideLangs: true,
};

export default withLayout(customProps)(NotFoundPage);
