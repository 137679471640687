import React from 'react';

const Nose = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18.067" height="13.479">
      <path
        d="M17.765 2.588C16.814-2.265-.791 1.172.16 6.035a9 9 0 0 0 8.817 7.321 9.013 9.013 0 0 0 8.97-8.97 8.969 8.969 0 0 0-.182-1.8"
        data-name="Path 294"
        stroke="#fff"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.24"
      />
    </svg>
  );
};

export default Nose;
