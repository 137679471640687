import React from 'react';

import { compose, position, bottom, zIndex } from 'styled-system';
import { Flex } from '@rebass/grid';
import Flower from '../Flowers';

const Flowers = () => {
  return (
    <Flex
      css={compose(
        position,
        zIndex,
        bottom,
      )}
      position="absolute"
      bottom={0}
      zIndex={2}
      width={1280}
      justifyContent="center"
    >
      <Flower.Error01 left={0} bottom={-40} />
      <Flower.Error02 left={100} bottom={15} />
      <Flower.Error03 left={208} bottom={-15} zIndex={2} />
      <Flower.Error04 left={373} bottom={17} />
      <Flower.Error05 left={419} bottom={121} zIndex={3} />
      <Flower.Error06 left={440} bottom={9} zIndex={2} />
      <Flower.Error07 right={435} bottom={17} zIndex={1} />
      <Flower.Error08 right={345} bottom={67} zIndex={0} />
      <Flower.Error09 right={365} bottom={55} />
      <Flower.Error10 right={155} bottom={40} zIndex={0} />
      <Flower.Error11 right={65} bottom={20} />
      <Flower.Error12 right={-15} bottom={11} />
    </Flex>
  );
};

export default Flowers;
