import React, { useState, useEffect, useRef } from 'react';

import { compose, position, top, left, zIndex } from 'styled-system';
import { Flex, Box } from '@rebass/grid';

import { Body, Eyes, Pupils, Nose, Mouth } from '../../assets/jungle-cat';

const Cat = ({ className }) => {
  const pupilsRef = useRef();

  const [eyesPosition, setEyesPosition] = useState({ top: 10, left: 10 });

  const handleMoveMouse = event => {
    const eyeLeftRange = { min: 10, max: 35 };
    const windowWidth = window.innerWidth;
    const mouseX = event.clientX;
    const percentagePositionX = Number((mouseX / windowWidth).toFixed(2));
    const newEyeLeft =
      eyeLeftRange.min + (eyeLeftRange.max - eyeLeftRange.min) * percentagePositionX;

    const eyeTopRange = { min: 10, max: 15 };
    const eyesPositionY = Math.round(pupilsRef.current.getBoundingClientRect().y);
    const mouseY = event.clientY;
    const percentagePositionY = Number(Math.sin(Math.PI * percentagePositionX).toFixed(2));
    const newEyeTopDown =
      eyeTopRange.min + (eyeTopRange.max - eyeTopRange.min) * percentagePositionY;
    const newEyeTop = mouseY > eyesPositionY ? newEyeTopDown : eyeTopRange.min;

    setEyesPosition({ top: newEyeTop, left: newEyeLeft });
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMoveMouse);
    return () => {
      window.removeEventListener('mousemove', handleMoveMouse);
    };
  }, []);

  return (
    <Flex className={className} justifyContent="center">
      <Body />
      <Flex
        css={`
          ${compose(
            position,
            top,
            left,
          )}
        `}
        flexDirection="column"
        alignItems="center"
        position="absolute"
        top={63}
        left={65}
      >
        <Box css={position} position="relative">
          <Eyes
            css={compose(
              position,
              zIndex,
            )}
            position="relative"
            zIndex={1}
          />
          <Pupils
            ref={pupilsRef}
            css={`
              ${position}
              transition: top 0.1s;
            `}
            position="absolute"
            style={eyesPosition}
          />
        </Box>
        <Nose />
        <Mouth
          css={compose(
            position,
            top,
            left,
          )}
          position="relative"
          top={-12}
          left={10}
        />
      </Flex>
    </Flex>
  );
};

export default Cat;
