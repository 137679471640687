import React from 'react';

import { compose, maxWidth, position, zIndex } from 'styled-system';
import { Flex } from '@rebass/grid';

import Cat from './Cat';
import Flowers from './Flowers';
import Waves from '../../assets/waves/bottom';

const Jungle = () => {
  return (
    <Flex
      css={compose(
        maxWidth,
        position,
      )}
      flexDirection="column"
      alignItems="center"
      width="100vw"
      maxWidth={1920}
      position="relative"
      mt={380}
    >
      <Cat
        css={`
          ${zIndex}
          position: absolute;
          bottom: 21px;
        `}
        zIndex={1}
      />
      <Waves
        css={compose(
          position,
          zIndex,
        )}
        position="relative"
        zIndex={1}
      />
      <Flowers />
    </Flex>
  );
};

export default Jungle;
