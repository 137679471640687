import React, { forwardRef } from 'react';

const Pupils = ({ className, style }, ref) => {
  return (
    <svg
      ref={ref}
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="75.857"
      height="6.683"
    >
      <defs />
      <g id="Group_243" data-name="Group 243" transform="translate(-1170.468 -4530.617)">
        <path
          id="Path_295"
          d="M51.694-67.679a3.344 3.344 0 0 1 3.342 3.341A3.343 3.343 0 0 1 51.694-61a3.343 3.343 0 0 1-3.343-3.341 3.344 3.344 0 0 1 3.343-3.338z"
          className="cls-1"
          data-name="Path 295"
          transform="translate(1191.288 4598.295)"
        />
        <path
          id="Path_296"
          d="M25.825-67.679a3.344 3.344 0 0 1 3.343 3.341A3.344 3.344 0 0 1 25.825-61a3.344 3.344 0 0 1-3.343-3.341 3.344 3.344 0 0 1 3.343-3.338z"
          className="cls-1"
          data-name="Path 296"
          transform="translate(1147.985 4598.295)"
        />
      </g>
    </svg>
  );
};

export default forwardRef(Pupils);
