import React from 'react';

const Mmouth = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="50.188" height="46.559">
      <path
        d="M1.885 38.837a15.25 15.25 0 0 0 19.064 3.184 15.277 15.277 0 0 0 5.565-20.769 15.214 15.214 0 0 0-1.7-2.386l.116.126a13.172 13.172 0 0 0 16.174 2.418 13.2 13.2 0 0 0 4.807-17.941 13.133 13.133 0 0 0-1.3-1.86"
        data-name="Path 293"
        fill="#fff"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="5"
      />
    </svg>
  );
};

export default Mmouth;
